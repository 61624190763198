import React, { useEffect, useState } from "react";
import "../Styles/App.css";
import HamburgerMenu from "../Menu/HamburgerMenu";
import ChatContainer from "../ChatContainer/ChatContainer";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import ErrorCard from "../Error/ErrorCardHome";
import LoadInicial from "../LoadingComponent/LoadInicial";
import menuimage2 from "../../img/apple.png";
import menuimage3 from "../../img/whatsappimg.png";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner/Banner";


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user, adicionarErro, adicionarRequest, retornaDieta} = UserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [suporte, setSuporte] = useState(false);
  const [dietaPronta, setDietaPronta] = useState(false)
  const { retornaTicket } = UserAuth();
  const [answer, setAnswer] = useState([]);
  const [userTickets, setUserTickets] = useState("");
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const LocalDiet = () => {
    navigate("/dieta");
  };

  useEffect(() => {
    retornaTicket()
      .then(async (tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
        } else {
          setUserTickets("Nenhum ticket disponível");
        }
      })
      .catch((error) => {
        setUserTickets("Erro ao obter os tickets");
      });
  }, [retornaTicket]);

  useEffect(() => {
      retornaDieta()
      .then((dieta) => {
    
          if(dieta){
              setDietaPronta(true)
            }else{
              setDietaPronta(false)
            }
            console.log('Chamado')
      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });
  }, [retornaDieta]);

  const wpp = () => {
    if (userTickets === "DietaTreino" || userTickets === "DietaNormal") {
      setSuporte(!suporte);
    } else {
      const numeroum = "5524999375062";
      const numerodois = "5524993329513";

      const numeros = [];
      function selecionarNumeroAleatoriamente() {
        numeros.push(numeroum);
        numeros.push(numerodois);

        const indiceAleatorio = Math.floor(Math.random() * numeros.length);
        return numeros[indiceAleatorio];
      }

      const whatsappURL = `https://api.whatsapp.com/send?phone=${selecionarNumeroAleatoriamente()}`;

      // Abre uma nova janela ou guia para iniciar a conversa no WhatsApp
      window.open(whatsappURL, "_blank");
    }
  };

  const sendRequestTreinoF = async (data, treino) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`,
        { data: data, treino: treino, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      console.error("Erro ao enviar a requisição:", error);

      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);

        // Aguardar antes de tentar novamente
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`,
            { data: data, treino: treino, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
    } else {
      // Algum outro erro foi acionado ao tentar fazer a requisição
      console.error("Erro na requisição:", error.message);
      adicionarErro("Erro ao enviar a requisição: " + error.message);
    }
    }
  };

  const sendRequestTreinoM = async (data, treino) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`,
        { data: data, treino: treino, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      console.error("Erro ao enviar a requisição:", error);

      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
           try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`,
            { data: data, treino: treino, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
  }else {
    // Algum outro erro foi acionado ao tentar fazer a requisição
    console.error("Erro na requisição:", error.message);
    adicionarErro("Erro ao enviar a requisição: " + error.message);
  }
    }
  };

  const sendRequest = async (data) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/GerarDieta`,
        { data: data, uid: user.uid }
      );
      setAnswer(result.data.answer);
      return;
    } catch (error) {
      // Adicionando mais detalhes ao erro, se disponível
      if (error.response) {
        // A resposta foi feita pelo servidor com um status fora do range de 2xx
        console.error("Detalhes do erro:", error.response.data);
        adicionarErro(`Erro ao enviar a requisição: ${error.response.data}`);
      } else if (error.request) {
        // O pedido foi feito mas não houve resposta
        adicionarErro(
          `Erro de rede ou servidor não respondeu. Tentativa: 1 ${error.message}`
        );
        await new Promise((res) => setTimeout(res, 5000));
        const retries = 2
        for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          // Segunda tentativa após o delay
          adicionarRequest(`Feita na ${attempt} requisição`)
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/GerarDieta`,
            { data: data, uid: user.uid }
          );
          setAnswer(result.data.answer);
          return;
        } catch (retryError) {
          console.error(
            "Erro ao enviar a requisição na segunda tentativa:",
            retryError
          );
          adicionarErro(
            `Erro na ${attempt} tentativa de envio da requisição: ${retryError.message}`
          );
          if (attempt === retries) {
            adicionarErro(`Excedido o número de tentativas. Saindo da função. ${retryError.message}`);
            return; // Número máximo de tentativas atingido, sair da função
          }
          await new Promise((res) => setTimeout(res, 4000)); // Aguardar 4 segundos
        }
      } 
      } else {
        console.error("Erro na requisição:", error.message);
        adicionarErro("Erro ao enviar a requisição: " + error.message);
      }
    }
  };

  // Exemplo de uso:

  return (
    <div className="App pb-5">
      {dietaPronta === true ? (
        <LoadInicial onClose={()=>setDietaPronta(false)}></LoadInicial>
      ): (<>
      <header className="App-header p-3">
        {/* Adicione o componente HamburgerMenu e lógica de alternância aqui */}
        <HamburgerMenu
          isOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          tickets={userTickets}
        />
      </header>
      <Banner/>
      <main className="App-main">
  <div className="body-menu border mx-auto mt-2 mb-0 ">
    <div className="menu-item w-full" tabIndex="0" onClick={LocalDiet}>
      <img
        src={menuimage2}
        className="menu-imagem1 "
        alt="Folha de Dieta"
      />
      Ver Dieta
    </div>
    <div className="menu-item w-full" tabIndex="0" onClick={wpp}>
      <img
        src={menuimage3}
        className="menu-imagem"
        alt="Computador para consulta"
      />
      Suporte
    </div>
  </div>
  <div>
    <br />
  </div>

        {/* Coloque aqui o componente ChatContainer com sendRequestToGPT e answer */}
        <ChatContainer
          sendRequest={sendRequest}
          sendRequestTreinoF={sendRequestTreinoF}
          sendRequestTreinoM={sendRequestTreinoM}
          answer={answer}
        />
      </main>
     
      {suporte && (
        <ErrorCard
          title={"Monte Sua Dieta"}
          message={
            "Preencha as informações novamente, selecione seus alimentos e depois clique em montar dieta"
          }
          onClose={wpp}
        ></ErrorCard>
      )}
      <br></br>
      <br></br>

      <div></div>
      </>)}
    </div>
  );
}

export default Home;
