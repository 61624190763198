import React from 'react';
import './ErrorCard1.css'
import { useNavigate } from 'react-router-dom';
import Espere from '../../img/Holdon.jpeg';

export default function ErrorCard({ id, message, onClose }) {
     const navigate = useNavigate()
     const openWhatsApp = () => {
    const numeros = [
      "5524999375062",
      "5524993329513"
    ];
    const numeroAleatorio = numeros[Math.floor(Math.random() * numeros.length)];
    const whatsappURL = `https://api.whatsapp.com/send?phone=${numeroAleatorio}`;
    window.open(whatsappURL, '_blank');
  };

      return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md mx-auto w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2">
            <div className="flex justify-center mb-4">
              <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden mx-1">
                <img src={Espere} alt="Pessoa 1" className="w-full h-full object-cover" />
              </div>
            </div>
            <h2 className="text-full font-bold">Pagamento Pendente</h2>
            <p className="text-md text-gray-600 mt-1"><strong> Depois preencha suas medidas</strong> e alimentos preferidos <strong>e conclua o pagamento</strong> para acessar.</p>
            <div className="mt-4 space-y-2">
              <button
                 onClick={() => navigate('/home')}
                className="bg-green-800 text-white hover:bg-green-700 px-3 py-2 rounded-full w-full flex justify-center items-center"
              >
                Montar Dieta
              </button>
              <button
            onClick={openWhatsApp}
            className="bg-[#17A34A] text-white hover:bg-[#17A34A]/80 px-3 py-2 rounded-full w-full flex justify-center items-center"
          >
            Fale conosco
          </button>
             
            </div>
          </div>
        </div>
      );
}
    